import { User } from '../user/user';

export class UserPermissions {
  // Can review warrants
  canReview: boolean;

  constructor(userGroups: User['groups']) {
    this.canReview = this.calcCanReview(userGroups);
  }

  /**
   * determine if user can review warrants
   * @param groups string[] - user's group memberships
   * @returns boolean
   */
  private calcCanReview(groups: User['groups']): boolean {
    return groups.includes('judge');
  }
}
