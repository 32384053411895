//* Imports
// Angular
import { Pipe, PipeTransform } from '@angular/core';
// Libphonenumber-js
import { AsYouType } from 'libphonenumber-js';

@Pipe({
  name: 'phone',
})
export class PhonePipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return value;
    }
    return new AsYouType('US').input(value);
  }
}
