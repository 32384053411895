//* Imports
// Angular
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
// Local Services
import { UserService } from '../../services/userService/user.service';
import { ErrorService } from '../../services/errorService/error.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  //* Constructor
  constructor(
    private userService: UserService,
    private router: Router,
    private errorService: ErrorService
  ) {}

  //* Methods
  /**
   * Can access the current page
   * @param route ActivatedRouteSnapshot
   * @param state RouterStateSnapshot
   * @returns If the user is capable of accessing the page or not
   */
  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const isLoggedIn = await this.userService.isLoggedIn();
    if (isLoggedIn) return true;
    else {
      this.router.navigate(['/login']);
      return false;
    }
  }
}
