//* Imports
// Angular
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  //* Methods

  /**
   * Handle errors given by 'catch' statements
   * @param error The error given by 'catch'
   */
  handleError(error: any) {
    console.error(error);
  }
}
