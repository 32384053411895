//* Imports
// Angular
import { Component, OnInit } from '@angular/core';
// Environment
import { environment } from 'src/environments/environment';
// Amplify
import { Amplify } from 'aws-amplify';
// Rxjs
import { Observable } from 'rxjs';
// Local Services
import { NavigationService } from './services/navigationService/navigation.service';
import { UserService } from './services/userService/user.service';
// Local Classes
import { NavigationItem } from './classes/navigationItem/navigation-item';
import { TimerService } from './services/timerService/timer.service';

//* AWS Amplify Configuration
Amplify.configure(environment.awsConf);

//* AWS Log Configuration
Amplify.Logger.LOG_LEVEL = environment.awsLogLevel;
@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  //* Observables
  /** Nav menu observable */
  public navMenu$: Observable<NavigationItem[]>;
  /** Profile menu observable */
  public profileMenu$: Observable<NavigationItem[]>;
  /** Jump menu observable */
  public jumpMenu$;
  /** Jump title observable */
  public jumpTitle$;

  //* Constructor
  constructor(
    private navigationService: NavigationService,
    private userService: UserService,
    private timerService: TimerService
  ) {}

  //* onInit
  ngOnInit() {
    // populate menu sections from observables
    this.navMenu$ = this.navigationService.navMenu;
    this.profileMenu$ = this.navigationService.profileMenu;
    this.jumpMenu$ = this.navigationService.jumpMenu;
    this.jumpTitle$ = this.navigationService.jumpTitle;
  }

  //* Methods
  /**
   * Logs the user out
   */
  logout() {
    this.userService.logoutUser();
  }
}
