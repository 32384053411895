export class CourtCompletionResponse {
  /** AWS ID of the search warrant */
  id?: string;
  /** Agency ID */
  agencyId?: string;
  /**
   * Current statuses
   * 'draft' | 'pending'  | 'courtManualPending' | 'courtSubmitted' | 'courtApproved' | 'courtDenied' | 'served' | 'notServed' | 'completed
   * Deprecated statuses
   * 'deptapproved' | 'manualapproval';
   **/
  status?:
    | 'draft'
    | 'pending'
    | 'courtManualPending'
    | 'courtSubmitted'
    | 'courtApproved'
    | 'courtDenied'
    | 'served'
    | 'notServed'
    | 'deptapproved'
    | 'manualapproval'
    | 'completed'
    | 'archived'; // archived added for Achive functionality
  /** Title of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerTitle?: string;
  /** First name of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerFirstName?: string;
  /** Last name of the affreviewer(judge) who confirmed the warrant as served */
  returnReviewerLastName?: string;
  /** Badge number of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerBadge?: string;
  /** Signature of the reviewer(judge) who confirmed the warrant as served */
  returnReviewerSignature?: string;
  /** When the return was reviewed */
  tsReturnReviewed?: string;

  // Create a constructor that accepts a Warrant object and maps the properties to the class properties
  constructor({
    id,
    agencyId,
    status,
    returnReviewerTitle,
    returnReviewerFirstName,
    returnReviewerLastName,
    returnReviewerBadge,
    returnReviewerSignature,
    tsReturnReviewed,
  }: CourtCompletionResponse) {
    this.id = id;
    this.agencyId = agencyId;
    this.status = status;
    this.returnReviewerTitle = returnReviewerTitle;
    this.returnReviewerFirstName = returnReviewerFirstName;
    this.returnReviewerLastName = returnReviewerLastName;
    this.returnReviewerBadge = returnReviewerBadge;
    this.returnReviewerSignature = returnReviewerSignature;
    this.tsReturnReviewed = tsReturnReviewed;
  }
}
